/* =============================================================
 * import
 * ========================================================== */
import {gsap} from "gsap/gsap-core";
import {eventObserver} from "./eventObserver";


/* =============================================================
 * globalNavi
 * グローバルナビに関する実行関数
 * ========================================================== */

export function globalNavi() {
  // const windowWidth = window.innerWidth;
  const hum = document.querySelector('.positive_header .hum');
  const humNega = document.querySelector('.negative_header .hum');

  // HumbergerMneu
  hum.addEventListener('click', humberger);
  humNega.addEventListener('click', humberger);
  const listeners = [
    [hum, 'click', humberger],
    [humNega, 'click', humberger],
  ];
  eventObserver.observeEvents( listeners );

}


/* =============================================================
 * humberger
 * ハンバーガーメニューの制御
 * ========================================================== */

export function humberger() {

  const html = document.documentElement;
  const hum = document.querySelector('.positive_header .hum');
  const humNega = document.querySelector('.negative_header .hum');
  const menu = document.getElementById('menu');
  const menuBg = document.querySelector('.menu_bg');
  const menuNav = document.querySelector('.menu_nav');
  const header = document.getElementById('header');
  const logo = document.querySelector('.positive_header .header_logo');
  const logoNega = document.querySelector('.negative_header .header_logo');
  const sideListElements = document.querySelectorAll('.header_gnav_list_item_link');
  const menuListElements = document.querySelectorAll('.menu_nav_list_item_txt');
  const menuChildListElements = document.querySelectorAll('.menu_nav_list_child_item_txt');
  const listeners = [];

  if (hum) {

    if (hum.classList.contains('close')) {
      // class
      hum.classList.remove('close');
      humNega.classList.remove('close');
      header.classList.remove('close');
    }

    if (hum.classList.contains('open')) {
      // class
      hum.classList.add('close');
      humNega.classList.add('close');
      header.classList.add('close');
      html.classList.remove('is-fixed');
      hum.classList.remove('open');
      humNega.classList.remove('open');
      header.classList.remove('open');

      // eventListener
      logo.removeEventListener('click', humberger);
      logoNega.removeEventListener('click', humberger);
      sideListElements.forEach((elem) => {
        elem.removeEventListener('click', humberger, false);
      });
      menuListElements.forEach((elem) => {
        elem.removeEventListener('click', humberger, false);
      });
      menuChildListElements.forEach((elem) => {
        elem.removeEventListener('click', humberger, false);
      });

      // gsap
      gsap.fromTo(menu,{
        y: '0%',
      },{
        y: '-100%',
        duration: 1.2,
        ease: 'scroll',
        delay: .4,
      });
      gsap.fromTo(menuBg,{
        y: '0%',
      },{
        y: '-100%',
        duration: 1.2,
        ease: 'scroll',
      });

      gsap.fromTo(menuNav,{
        y: '0%',
        opacity: 0,
      },{
        y: '-100%',
        opacity: 1,
        duration: 1.2,
        ease: 'scroll',
      });

      gsap.utils.toArray('.negative_header').forEach(target => {
        gsap.fromTo(target, {
          webkitClipPath: 'inset(0% 0% 0% 0%)',
          clipPath: 'inset(0% 0% 0% 0%)',
          // scrollTrigger: scrollOptions,
        }, {
          webkitClipPath: 'inset(0% 0% 100% 0%)',
          clipPath: 'inset(0% 0% 100% 0%)',
          duration: 1.2,
          ease: 'scroll',
          delay: .1,
        });
      });

    } else {
      // class
      html.classList.add('is-fixed');
      hum.classList.add('open');
      humNega.classList.add('open');
      header.classList.add('open');

      // eventListener
      logo.addEventListener('click', humberger);
      logoNega.addEventListener('click', humberger);
      listeners.push([logo, 'click', humberger]); // reset
      listeners.push([logoNega, 'click', humberger]); // reset

      // gsap
      sideListElements.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
        listeners.push([elem, 'click', humberger, false]);
      });
      menuListElements.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
        listeners.push([elem, 'click', humberger, false]);
      });
      menuChildListElements.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
        listeners.push([elem, 'click', humberger, false]);
      });
      gsap.fromTo(menu,{
        y: '100%',
      },{
        y: '0%',
        duration: 1.2,
        ease: 'scroll',
      });
      gsap.fromTo(menuBg,{
        y: '100%',
      },{
        y: '0%',
        duration: 1.2,
        ease: 'scroll',
        delay: .4,
      });
      gsap.fromTo(menuNav,{
        y: '10%',
        opacity: 0,
      },{
        y: '0%',
        duration: 1.6,
        opacity: 1,
        ease: 'scroll',
        delay: .6,
      });
      gsap.utils.toArray('.negative_header').forEach(target => {
        gsap.fromTo(target, {
          webkitClipPath: 'inset(100% 0% 0% 0%)',
          clipPath: 'inset(100% 0% 0% 0%)',
          // scrollTrigger: scrollOptions,
        }, {
          webkitClipPath: 'inset(0% 0% 0% 0%)',
          clipPath: 'inset(0% 0% 0% 0%)',
          duration: 1.2,
          ease: 'scroll',
          delay: .1,
        });
      });
    }
  }

}

