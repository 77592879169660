/* =============================================================
 * import
 * ========================================================== */
import {CustomEase} from "gsap/CustomEase";
import {gsap} from "gsap/gsap-core";
import TextWrap from "../../modules/textWrap";
const tl = gsap.timeline();


/* =============================================================
 * functions
 * ========================================================== */
export function leave(data, duration = 1000) {
  const ms = duration / 1000;

  gsap.set(data.current.container, {
    opacity: 1,
  });

  gsap.to(data.current.container, {
    opacity: 0,
    duration: ms,
    ease: "Power2.easeOut",
  });

  /*
  gsap.fromTo(data.current.container, {
    opacity: 1,
  }, {
    opacity: 0,
    // ease: CustomEase.create("custom", ".645, .045, .255, 1"),
    ease: "Power2.easeOut",
    duration: ms,
  });
  */

}


export function enter(data) {

  gsap.set(data.next.container, {
    opacity: 0,
  });

  const elm = [...document.querySelectorAll("[data-trans-namespace=\"" + data.next.namespace + "\"] .js-txt-up")];
  elm.forEach((e) => {
    new TextWrap(e);
  });
}


export function after(data, duration = 1000) {
  const ms = duration / 1000;

  /*
    const elm = [...document.querySelectorAll("[data-trans-namespace=\"" + data.next.namespace + "\"] .js-txt-up")];
    let txt = '';
    let s = 0;
    elm.forEach((e) => {
      txt = e.querySelectorAll('._txt');
      txt.forEach((t) => {

        gsap.fromTo(t, {
          opacity: 0,
          y: '80%',
        }, {
          opacity: 1,
          y: 0,
          ease: "Power2.easeOut",
          duration: .8,
          delay: s,
        });

        s = s + .05;
      });
    });
  */

  tl
    .fromTo(
      data.next.container,
      {
        opacity: 0,
      }, {
        opacity: 1,
        ease: CustomEase.create("custom", ".645, .045, .255, 1"),
        duration: ms,
      },
    )
  ;

  const ttl = document.querySelector("[data-trans-namespace=\"" + data.next.namespace + "\"] > [data-scroll-section]:first-child");
  // const ttlJa = document.querySelector("[data-trans-namespace=\"" + data.next.namespace + "\"] > [data-scroll-section]:first-child .ttl_ja");
  // const ttlEn = document.querySelector("[data-trans-namespace=\"" + data.next.namespace + "\"] > [data-scroll-section]:first-child .ttl_en");
  ttl.classList.add('is-active');
  // ttlJa.classList.add('is-active');
  // ttlEn.classList.add('is-active');
}

