/* =============================================================
 * import
 * ========================================================== */
import {locoScroll} from "./parallax";
import TextWrap from "./textWrap";
// import {gsap} from "gsap/gsap-core";
// import {CustomEase} from "gsap/CustomEase";


/* =============================================================
 * mediaQuery
 * レスポンシブ判定
 * ========================================================== */
export function mediaQuery(windowWidth = window.innerWidth) {
  if (windowWidth <= 768) {
    return 'sp';
  } else {
    return 'pc';
  }
}


// =============================================================
// smoothScroll
// スムーズなスクロールの処理
// =============================================================
export function smoothScroll(hash, duration = 1000, event) {
  const ms = duration/1000;

  // 調整値の計算
  const header = document.querySelector('.hum');

  let offset = 0;
  if (mediaQuery() === 'sp') {
    offset = -header.clientHeight;
  }

  let target;
  if (hash !== undefined && hash !== null && hash !== '') {
    target = document.querySelector(hash);
    locoScroll.scrollTo(target, {
      duration: ms,
      offset: offset,
    });
  } else {
    /*
    安定しない。
    特にSPにおいて、ページTOPまで戻り切らないケースが多発。
    途中で処理が中断されるのか、取得できているTOPの位置がbarabaJSのコンテンツ切り替え時に移動するのか、現状不明。
    locoScroll.scrollTo('top', {
      duration: duration,
    });
    */
    // window.scroll(0, 0); // SPでは有効。PCでは効かないことが多い。不安定。
    locoScroll.setScroll(0, 0); // なぜか効かない。シンプルに内容誤解してるかも。
    locoScroll.scrollTo('top', {
      duration: ms,
    });
  }

  if (event !== undefined) {
    event.preventDefault(); // barabaでの非同期遷移後に、#付きリンクがクリックされると、スクロールイベントによって通常のスクロールが発生することを防ぐ
  }
}


// =============================================================
// scrollResetOnLoad
// 読み込み時のスクロール位置リセット
// =============================================================
export function scrollResetOnLoad(hash = window.location.hash) {

  if (hash !== '' && mediaQuery() === 'pc') {
    // PCの場合、通常のスクロールが動き、位置がずれてしまうので、強制的に戻す。
    // SPの場合、こちらを入れると、逆にずれてしまうので、不要。
    window.scroll(0, 0);
  }

  locoScroll.update(); // 慣性スクロールのリセット
  
}


// =============================================================
// 指定テキストのspan囲み
// =============================================================
export function txtUp(elm = [...document.querySelectorAll(".js-txt-up")]) {
  elm.forEach((e) => {
    new TextWrap(e);
  });

  /*
  let txt = '';
  let s = 0;
  elm.forEach((e) => {
    txt = e.querySelectorAll('._txt');
    txt.forEach((t) => {

      gsap.fromTo(t, {
        opacity: 0,
        y: '30%',
      }, {
        opacity: 1,
        y: 0,
        ease: CustomEase.create("custom", ".645, .045, .255, 1"),
        duration: .8,
        delay: s,
      });

      s = s + .03;
    });
  });
  */
}