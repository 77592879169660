/* =============================================================
 * import
 * ========================================================== */
import $ from 'jquery';
import {locoScroll} from "../modules/parallax";
import {gsap} from "gsap/gsap-core";
import bowser from 'bowser';
import {mediaQuery} from "../modules/common";
const browser = bowser.getParser(window.navigator.userAgent);
import {eventObserver} from "../modules/eventObserver";


/* =============================================================
 * export function
 * ========================================================== */
export function before() {
  const $body = document.body;
  $body.classList.remove('top_page');
  $body.classList.add('sub_page');
}


export function cursor() {

  if (!browser.is('mobile') && !browser.is('tablet')) {
    const cursor = document.getElementById('cursor');
    // const pos = { x: 0, y: 0 }; // カーソル要素の座標
    // const mouse = { x: pos.x, y: pos.y }; // マウスカーソルの座標
    const speed = 1;

    // leader
    const leaderID = 'cursor_leader';
    if (document.getElementById(leaderID) == null) {
      const leader = document.createElement('span');
      leader.id = leaderID;
      leader.className = leaderID;
      cursor.appendChild(leader);
    }

    // leader_filled
    const filledID = 'cursor_filled';
    if (document.getElementById(filledID) == null) {
      const filled = document.createElement('span');
      filled.id = filledID;
      filled.className = filledID;
      cursor.appendChild(filled);
    }

    // カーソルの座標を設定する
/*
    const cursorSetX = gsap.utils.pipe(
      gsap.utils.clamp(0, 100),
      gsap.utils.snap(5),
      gsap.quickSetter(cursor, "x", "px"),
    );
    const cursorSetY = gsap.utils.pipe(
      gsap.utils.clamp(0, 100),
      gsap.utils.snap(5),
      gsap.quickSetter(cursor, "y", "px"),
    );
*/
    // const cursorSetX = gsap.quickSetter(cursor, "x", "px");
    // const cursorSetY = gsap.quickSetter(cursor, "y", "px");

    /*
    const mouseMove = {
      e: e,
      handleEvent: mouseAnimation,
    };
    */

    document.addEventListener('mousemove', function(e) {
      // mouse.x = e.pageX;
      // mouse.y = e.pageY;
      gsap.to(cursor, {
        duration: speed,
        x: e.pageX,
        y: e.pageY,
        ease: "Power4.easeOut",
        delay: .2,
      });
    });

    /*
    gsap.ticker.add(() => {
      // const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());
      // pos.x += (mouse.x - pos.x) * dt;
      // pos.y += (mouse.y - pos.y) * dt;
      // cursorSetX(pos.x);
      // cursorSetY(pos.y);
      /!*
      followerSetX(pos.x);
      followerSetY(pos.y);
      *!/
      gsap.to(cursor, {
        duration: speed,
        x: mouse.x,
        y: mouse.y,
        ease: "Power4.easeOut",
        delay: .3,
      });

    });
    */

  }
}

export function cursorAnimation() {
  const listeners = [];

  if (!browser.is('mobile') && !browser.is('tablet')) {
    const cursor = document.getElementById('cursor');
    const linkElem = document.querySelectorAll('a:not(.cursor_circle), .cursor_dot');
    const addActive = () => {
      cursor.classList.add('active');
    };
    const removeActive = () => {
      cursor.classList.remove('active');
    };
    const addActiveCircle = () => {
      cursor.classList.add('active_circle');
    };
    const removeActiveCircle = () => {
      cursor.classList.remove('active_circle');
    };
    for (let i = 0; i < linkElem.length; i++) {
      linkElem[i].addEventListener('mouseover', addActive);
      linkElem[i].addEventListener('mouseout', removeActive);
      listeners.push([linkElem[i], 'mouseover', addActive]);
      listeners.push([linkElem[i], 'mouseout', removeActive]);
    }

    const linkElemCircle = document.querySelectorAll('.cursor_circle');
    for (let i = 0; i < linkElemCircle.length; i++) {
      linkElemCircle[i].addEventListener('mouseover', addActiveCircle);
      linkElemCircle[i].addEventListener('mouseout', removeActiveCircle);
      listeners.push([linkElemCircle[i], 'mouseover', addActiveCircle]);
      listeners.push([linkElemCircle[i], 'mouseout', removeActiveCircle]);
    }
  }

  eventObserver.observeEvents( listeners );
}

export function headerScroller() {
  let kv = document.getElementById('kv');
  const body = document.body;

  if (!kv) {
    kv = document.querySelector('.mv');
  }

  if (kv && mediaQuery() === 'pc') {
    const padding = parseInt(window.getComputedStyle(kv).getPropertyValue('padding-top'));

    // スクロールに合わせて上昇
    locoScroll.on("scroll", (e) => {
      if (e.scroll.y > padding) {
        body.classList.add('scrolled');
      } else {
        body.classList.remove('scrolled');
      }
    });

  }
}

export function after() {
  const btn = document.querySelectorAll('.totop');
  if (btn.length > 0) {
    btn.forEach(elem => {
      elem.addEventListener('click', {
        elem: elem,
        handleEvent: toTop,
      }, false);
    });
  }

  const mailAddress = document.querySelectorAll('.js-mailAddress');
  const ad = converter(String.fromCharCode(104,109,101,110,63,114,120,116,44,108)+String.fromCharCode(45,105,111)); // info@syu-m.jp
  if (mailAddress.length > 0) {
    mailAddress.forEach(elem => {
      elem.innerHTML = ad;
    });
  }

  // Common.txtUp();
  slideDownBtn();
  headerScroller();
  cursorAnimation();
}


function converter(mail){
  let str = "";
  let strAs = "";
  for(let i=0; i<mail.length; i++){
    strAs = mail.charCodeAt(i);
    str += String.fromCharCode(strAs + 1);
  }
  return str;
}


/*
export function kvMouseStoker() {

  const wrap = document.querySelector('#kv'); // target

  if (wrap) {
    let target1 = wrap.querySelector('.kv_bg .sakura_pc.sakura_scale');
    let target2 = wrap.querySelector('.kv_bg .sakura_pc.sakura_normal');
    const target3 = wrap.querySelector('.kv_figure');
    const target3Inner = wrap.querySelector('.kv_figure_img');
    const target4 = wrap.querySelectorAll('.kv_figure_sub_item');
    const target4Inner = wrap.querySelectorAll('.kv_figure_sub_img');
    const target5 = wrap.querySelectorAll('.kv_headline');

    const w = window.innerWidth;              // マウス移動領域の横幅（通常はスクロールバーを除く描画領域）
    const h = window.innerHeight;             // マウス移動領域の高さ（通常はスクロールバーを除く描画領域）

    const mouse = {x: 0, y: 0};
    const ratio = {x: 0, y: 0};
    const rotate1 = {x: 0, y: 0};
    const rotate2 = {x: 0, y: 0};
    const translate1 = {x: 0, y: 0};
    const translate2 = {x: 0, y: 0};
    const translate3 = {x: 0, y: 0};
    const translate4 = {x: 0, y: 0};

    let deg = 25;                           // 最大傾斜（deg）
    let deg2 = 40;                          // 最大傾斜（deg）
    let px = 40;                            // 最大移動幅（px）
    let px2 = 60;                           // 最大移動幅（px）
    let px3 = 80;                           // 最大移動幅（px）
    let px4 = 20;                           // 最大移動幅（px）

    if (!browser.is("mobile") && !browser.is("tablet")) {


      wrap.addEventListener('mousemove', function(e) {

        mouse.x = e.clientX;    // マウス位置 - 横
        mouse.y = e.clientY;    // マウス位置 - 縦
        ratio.x = mouse.x / w;  // マウス移動率（横）（×100で%）
        ratio.y = mouse.y / h;  // マウス移動率（縦）（×100で%）
        move(ratio.x, ratio.y, target1, target2);

      });

    } else {

      window.addEventListener('deviceorientation', function(e) {

        target1 = wrap.querySelector('.kv_bg .sakura_sp.sakura_scale');
        target2 = wrap.querySelector('.kv_bg .sakura_sp.sakura_normal');

        mouse.x = e.gamma;       // マウス位置 - 横
        mouse.y = e.beta;        // マウス位置 - 縦
        ratio.x = mouse.x;       // デバイス傾斜率（横）（×100で%）
        ratio.y = mouse.y;       // デバイス傾斜率（縦）（×100で%）

        deg = .1;                // 最大傾斜（deg）
        deg2 = .1;               // 最大傾斜（deg）
        px = .1;                 // 最大移動幅（px）
        px2 = .2;                // 最大移動幅（px）
        px3 = .1;                // 最大移動幅（px）
        px4 = .2;                // 最大移動幅（px）

        move(ratio.x, ratio.y, target1, target2);

      });

    }

    const move = (x, y, target1, target2) => {
      ratio.x = x;                              // 移動率（横）（×100で%）
      ratio.y = y;                              // 移動率（縦）（×100で%）
      rotate1.x = (ratio.y * deg * 2) - deg;    // 回転率（横）
      rotate1.y = (ratio.x * deg * 2) - deg;    // 回転率（縦）
      rotate2.x = (ratio.y * deg2 * 2) - deg2;  // 回転率（横）
      rotate2.y = (ratio.x * deg2 * 2) - deg2;  // 回転率（縦）
      translate1.x = (ratio.x * px * 2) - px;   // 移動率（横）
      translate1.y = (ratio.y * px * 2) - px;   // 移動率（縦）
      translate2.x = (ratio.x * px2 * 2) - px2;  // 移動率（横）
      translate2.y = (ratio.y * px2 * 2) - px2;  // 移動率（縦）
      translate3.x = (ratio.x * px3 * 2) - px3;  // 移動率（横）
      translate3.y = (ratio.y * px3 * 2) - px3;  // 移動率（縦）
      translate4.x = (ratio.x * px4 * 2) - px4;  // 移動率（横）
      translate4.y = (ratio.y * px4 * 2) - px4;  // 移動率（縦）

      gsap.to(target1, {
        duration: 3,
        rotationY: rotate1.y,
        rotationX: rotate1.x,
        ease: "Power4.easeOut",
      });

      gsap.to(target2, {
        duration: 5,
        rotationY: rotate2.y,
        rotationX: rotate2.x,
        ease: "Power4.easeOut",
      });

      gsap.to(target3, {
        duration: 2,
        x: translate1.x,
        y: translate1.y,
        ease: "Power4.easeOut",
      });

      gsap.to(target3Inner, {
        duration: 4,
        x: translate2.x,
        y: translate2.y,
        ease: "Power4.easeOut",
      });

      gsap.to(target5, {
        duration: 1,
        x: translate4.x,
        y: translate4.y,
        ease: "Power4.easeOut",
      });

      target4.forEach(elem => {
        gsap.to(elem, {
          duration: 3,
          x: translate3.x,
          y: translate3.y,
          ease: "Power4.easeOut",
        });
      });

      target4Inner.forEach(elem => {
        gsap.to(elem, {
          duration: 2,
          x: translate3.x,
          y: translate3.y,
          ease: "Power4.easeOut",
        });
      });
    };
  }

}
*/


/* =============================================================
 * function
 * ========================================================== */
function slideDownBtn() {
  const btn = $("#slideDownBtn");
  const area = $("#slideDownArea");
  if (btn.length) {
    btn.click(function() {
      btn.toggleClass('open');
      area.toggleClass('open').slideToggle();
    });
  }
}


export function toTop() {
  locoScroll.scrollTo('top', {
    duration: 100,
  });
}

