/* =============================================================
 * import
 * ========================================================== */
import Swiper from 'swiper/bundle';
import {gsap} from "gsap/gsap-core";
import {CustomEase} from "gsap/CustomEase";

gsap.registerPlugin(CustomEase);
CustomEase.create('scroll', '0.32, 0.95, 0.6, 1');

/* =============================================================
 * function
 * ========================================================== */


export default function exe() {
  columnSwiper();

  window.addEventListener('resize', function() {
    columnSwiper();
  });
}

export function columnSwiper() {

  const swiper = new Swiper('.swiper', { // eslint-disable-line @typescript-eslint/no-unused-vars
    // modules: [Navigation, Autoplay, Scrollbar],
    slidesPerView: 'auto', // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
    speed: 1200, // スライドアニメーションのスピード（ミリ秒）

    // cssMode: true,              // true: CSS Scroll Snap APIが適用
    // mousewheel: true,           // true: マウスホイールでの操作を許可
    // keyboard: true,             // true: キーボードでの操作を許可
    grabCursor: true,           // PCでマウスカーソルを「掴む」マークにする
    watchSlidesProgress: true,  // スライドの進行状況を監視する

    /*
    autoplay: { // 自動再生させる
      delay: 3000, // 次のスライドに切り替わるまでの時間（ミリ秒）
      disableOnInteraction: true, // ユーザーが操作しても自動再生を止めない
      waitForTransition: true, // アニメーションの間も自動再生を止めない（最初のスライドの表示時間を揃えたいときに）
    },
    loop: true,  // 無限ループさせる
    loopAdditionalSlides: 1, // 無限ループさせる場合に複製するスライド数
    freeModeSticky: true,
    */

    freeMode: {
      enabled: true, // true：自由にドラッグ（スワイプ）できるようにする。スライド位置がスナップしなくなる
      momentum: true, // false：ドラッグ（スワイプ）した後の慣性スクロールをオフにする
      momentumRatio: .1, // 数字が大きいほど惰性スクロールの距離が伸びる（freeMode: trueとfreeModeMomentum:trueが前提）
      modeMomentumVelocityRatio: .2, // 数字が大きいほど惰性スクロールのスピードが速くなる（freeMode: trueとfreeModeMomentum:trueが前提）
      sticky: true,
    },

    preventClicks: true, // true: スワイプ中の不要なクリックを無効化
    preventClicksPropagation: true, // true: スワイプ中の不要なクリックイベントを無効化

    navigation: {
      nextEl: '.swiper-button-next', // 「次へ」ボタン要素のクラス
      prevEl: '.swiper-button-prev', // 「前へ」ボタン要素のクラス
    },

    scrollbar: {
      el: '.swiper-scrollbar', // スクロールバー要素のクラス
      draggable: true,
    },
  });

  // return mySwiper;
}
