/* =============================================================
 * import
 * ========================================================== */
import $ from 'jquery';

/* =============================================================
 * function
 * ========================================================== */

export default function exe() {
  // googleReCaptchaV3();
  formAddErrorClass();
  exchangeForm();
  /*
  window.addEventListener('resize', function() {
  });
  */
}


function formAddErrorClass () {
  if ($(".mw_wp_form").length) {
    $(".form_list_cnt").each(function () {
      if ($(this).find('.error').length) {
        $(this).addClass('error_field');
      }
    });
  }
}


function exchangeForm () {
  if ($(".mw_wp_form").length) {
    const triggerName = 'お問い合わせ種別';
    $('[name="' + triggerName + '"]:radio').change(function() {
      exchangeFormTable($('[name="' + triggerName + '"]:checked').val());
    });
    exchangeFormTable($('[name="' + triggerName + '"]:checked').val());
  }
}


function exchangeFormTable (v) {
  if (v === '取材のご依頼' || v === 'その他' ) {
    $('.list_company').slideDown();
  } else {
    $('.list_company').slideUp();
  }
  /*
  if (v === '選択肢１' || v === '選択肢２' ) {
    $('.desc_data').slideDown();
    const msgCourse = '（例）以下のコースへの申し込みを検討しています。\nコース名：' + $("#course_target").val() + $("#course_name").val() + $("#course_plan_class").val() + $("#course_plan_name").val();
    const msgTrial = '無料体験レッスンに申し込みます。';
    const msg = $('#message').val();
    if (v === '選択肢１' && (msg === '' || msg === msgTrial) ) {
      $('#message').val(msgCourse);
    }

    if (v === '選択肢２' && (msg === '' || msg === msgCourse) ) {
      $('#message').val(msgTrial);
    }

  } else {
    $('.desc_data').slideUp();
    $('#message').val('');
  }
  */
}

/*
function googleReCaptchaV3() {
  const mwWpForm = document.querySelector('.mw_wp_form > form');
  const mwWpFormSubmit = document.querySelector('input[type="submit"][name="submitConfirm"]');
  mwWpForm.addEventListener('submit', onReCAPTCHA);

  // リキャプチャの制御
  function onReCAPTCHA(e) {
    e.preventDefault();
    grecaptcha.ready(function() { // eslint-disable-line no-undef
      grecaptcha.execute('6LeAAKMgAAAAAFCiPUgfseoXlbiMFJOIFhSaZuyb', { // eslint-disable-line no-undef
        action: 'submit',
      }).then(function(token) {
        const recaptchaResponse = document.querySelector('input[name="recaptcha-v3"]');
        recaptchaResponse.value = token;
        mwWpForm.submit();
      });
    });
  }

  const mwWpFormPrivacy = document.querySelector('#privacy-1');
  disabledSubmit(mwWpFormPrivacy);
  mwWpFormPrivacy.addEventListener('change', disabledSubmit);

  // プライバシーポリシーのチェックに応じてボタンをdisabled
  function disabledSubmit(event) {
    let flg;
    if (event.target === undefined) {
      flg = event.checked;
    } else {
      flg = event.target.checked;
    }

    if (flg == true) {
      mwWpFormSubmit.disabled = false;
    } else {
      mwWpFormSubmit.disabled = true;
    }
  }
}
*/


/*
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 * @return  url {url} 対象のURL文字列（任意）
 */

/*
function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
*/
